// NAVIGATION
export const NAV_LINKS = [
    { href: '/about', key: 'About us', label: 'About Us' },
    { href: '/courses', key: 'Courses', label: 'Courses' },
    { href: '/resources', key: 'Resources', label: 'Resources' },
    { href: '/pricing', key: 'Pricing ', label: 'Pricing ' },
    { href: '/contact', key: 'Contact_us', label: 'Contact Us' },
  ];
  
  
  // FOOTER SECTION
  export const FOOTER_LINKS = [
    {
      title: 'Learn More',
      subtitles: [
        { subtitle: 'About Oracy', link: '/about' },
        { subtitle: 'Press Releases', link: '/about/press-releases' },
        { subtitle: 'Privacy Policy', link: '/privacypolicy' },
        { subtitle: 'Contact Us', link: '/contact' }, 
      ],
    },
  ];
  
  export const FOOTER_CONTACT_INFO = {
    title: 'Contact Us',
    links: [
      { label: 'Whatsapp', value: '+91 9041221631' },
      { label: 'Email', value: 'hello@oracy.uk' },
    ],
  };
  
  export const SOCIALS = {
    title: 'Social',
    links: [
      {url : 'https://www.facebook.com/OracyEducationLimited', imgurl: '/facebook.svg'},
      {url : 'https://www.instagram.com/oracyeducationltd/', imgurl: '/instagram.svg'},
      {url : 'https://x.com/oracyedu', imgurl: '/twitter.svg'},
      {url : 'https://www.youtube.com/channel/UCBCm9ivI5hBmrOBEkP16WHw', imgurl: '/youtube.svg'},
      {url : 'https://www.linkedin.com/in/oracy-admin-381a19304/', imgurl: '/linkedin.svg'}
      
    ],
  };

  export const BENEFITS = [
    {
      title: 'Personalised Learning Experience',
      description: 'Oracy employs personalised online teaching methods that cater to each child\'s unique learning style. This approach is built upon engaging storytelling techniques, which not only make learning enjoyable but also improve knowledge retention.'
    },
    {
      title: 'Proven Academic Improvement',
      description: 'Statistics indicate that 97% of Oracy students perform better in school exams compared to their peers, with significant improvements noted after just a few classes. This suggests that the platform effectively enhances academic outcomes through its tailored teaching methods.'
    },
    {
      title: 'Certified Teachers',
      description: 'Oracy recruits internationally certified teachers who are trained to provide high-quality education. This ensures that your child is learning from professionals who understand how to engage and motivate students effectively.'
    },
    {
      title: 'Affordable Options',
      description: 'Oracy offers an affordable solution for parents seeking quality education for their children. The platform also provides trial classes, allowing families to assess the fit before committing fully.'
    },
    {
      title: 'Positive Feedback from Parents',
      description: 'Many parents have reported significant improvements in their children\'s confidence and skills after enrolling them in Oracy. Testimonials highlight the interactive and engaging nature of the lessons, as well as the supportive environment fostered by the teachers.'
    }
  ];

  

  export const Teachers = [
    {
      name: 'Mr Raphael Brown',
      title: 'Specialist in Oracy, Educational Research, and Teacher Training',
      image: '/raph.webp',
      description: 'Mr Brown is an accomplished educator with a profound commitment to enhancing oracy and fostering effective soft skills in educational settings. With a strong academic background and extensive experience, Mr Brown is dedicated to providing a holistic approach to education, inspiring both students and professionals to achieve their full potential.',
      qualifications: [
        'Master of Arts (MA) in Education',
        'Member of the Chartered College of Teaching (MCCT)',
        'Bachelor of Arts (Hons) in Business Management & Geography',
        'Postgraduate Certificate in Education (PGCE)',
        'National Professional Qualification for Senior Leadership (NPQSL)',
        'National Professional Qualification for Leading Teacher Development (NPQLTD)'
      ],
      experience: [
        'Educational Research & Development',
        'Training & Coaching',
        'Lecturing & Seminars',
        'English Language Instruction',
        'Coaching & Instructional Strategies'
      ],
      personalInterests: [
        'Outdoor Activities & Sports',
        'Community & Governance',
        'Volunteering & Cultural Engagement'
      ]
    },
    {
      name: 'Ms Ellie Hives',
      title: 'Specialist in Oracy, E-Learning, and Inclusive Education',
      image: '/elle.webp',
      description: 'Ms Hives is a passionate educator who excels in creating dynamic learning environments through the integration of oracy and research-based teaching practices. Committed to fostering a culture of communication and inclusivity, Ms Hives ensures every student has the opportunity to succeed.',
      qualifications: [
        'Bachelor of Arts in Education',
        'Qualified Teacher Status (QTS)'
      ],
      skills: [
        'Implementation of Oracy Strategies',
        'International E-Learning Experience',
        'Research on Neurological Disorders',
        'Innovative Teaching Strategies'
      ],
      personalInterests: [
        'Sports & Athletics',
        'Supporting Daughter’s Gymnastics Career',
        'Reading & Family Time',
        'Travel & Cultural Exploration'
      ]
    }
  ];
  
  


  export const faqSchema = {
    "@context": "http://schema.org",
    "@type": "FAQPage",
    "mainEntity": [
      {
        "@type": "Question",
        "name": "What are the four components of oracy?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Physical: This covers voice projection, eye contact, and gestures. Linguistic: This is the vocabulary, grammar, and articulation of language used. Cognitive: These are activities that involve organizing thoughts or reasoning and making arguments. Social and Emotional: These are the attitudes we develop concerning fellow human beings like kindness, listening, and showing empathy."
        }
      },
      {
        "@type": "Question",
        "name": "What is oracy and why is it important for your child?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Oracy refers to the ability to speak effectively, similar to numeracy in mathematics or literacy in reading and writing. In summary, oracy entails clarity and expression in communication."
        }
      },
      {
        "@type": "Question",
        "name": "What is the objective of oracy?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "The objective of oracy is to ensure that individuals consider the appropriate syntactic structures they use, taking into account the topic, purpose, and audience. Effective communication is essential in many aspects of life, highlighting the importance for children to recognise its value from an early age."
        }
      },
      {
        "@type": "Question",
        "name": "What are the costs associated with Oracy?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Oracy offers affordable pricing and trial classes so families can assess whether it is the right fit before making long-term commitments. This approach provides access to quality education at a reasonable cost and allows families to explore what Oracy offers without financial pressure."
        }
      },
      {
        "@type": "Question",
        "name": "How can I get started with Oracy?",
        "acceptedAnswer": {
          "@type": "Answer",
          "text": "Firstly, register via our website to experience how well Oracy’s programmes suit your child. A sample session will introduce you to our teaching strategies, syllabus, and general pedagogical approach, helping you determine how Oracy meets your child's educational needs."
        }
      }
    ]
  };
  

export const addressSchema = {
  "@context": "http://schema.org",
  "@type": "Organization",
  "name": "Oracy Education Ltd.",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "86-90 Paul Street",
    "addressLocality": "Paul-Street",
    "addressRegion": "London",
    "postalCode": "12345",
    "addressCountry": "UK"
  },
  "contactPoint": {
    "@type": "ContactPoint",
    "telephone": "+1-800-555-5555",
    "contactType": "Customer Service",
    "areaServed": "US",
    "availableLanguage": "English"
  }
};

export const coursedata = {
  Essential: {
    Physical: {
      title: "Move and Groove: Exploring Body Language",
      description: "This introductory course helps young children understand the basics of body language and gestures. Through fun activities and games, kids will learn how to express themselves physically and build confidence in their movements.",
      keyComponents: [
        "Weekly Interactive Lessons (30 minutes each)",
        "Gesture Games: Fun activities that teach children common gestures and their meanings (e.g., thumbs up, waving).",
        "Movement Activities: Simple exercises that encourage children to express emotions through movement (e.g., happy dance, sad walk).",
        "Storytelling with Actions: Using gestures and body movements to tell stories, enhancing creativity and expression.",
        "Downloadable Activity Cards: Visual prompts for practicing gestures and movements at home."
      ],
      skillsDeveloped: [
        "Basic understanding of body language and gestures",
        "Confidence in physical expression",
        "Creativity in storytelling through movement"
      ]
    },
    Linguistic: {
      title: "Discover the Magic of Words: Linguistic Foundations",
      description: "This introductory course helps young children develop a strong foundation in language skills, including vocabulary, grammar, and storytelling. Through interactive lessons and fun activities, kids will explore the wonders of language and communication.",
      keyComponents: [
        "Weekly Interactive Lessons (30 minutes each)",
        "Vocabulary Building: Engaging games and activities that introduce new words and their meanings.",
        "Grammar Basics: Simple explanations and exercises that teach basic grammar concepts.",
        "Storytelling Adventures: Creative writing prompts and story starters to encourage imagination and language expression.",
        "Downloadable Language Workbooks: Practice sheets and activities for reinforcing language skills."
      ],
      skillsDeveloped: [
        "Expanded vocabulary",
        "Understanding of basic grammar rules",
        "Storytelling and creative writing abilities"
      ]
    },
    Cognitive: {
      title: "Brain Boost: Discover Your Thinking Power",
      description: "This interactive online course introduces young children to the basics of cognitive skills through engaging activities and games. The focus is on developing attention, memory, and problem-solving abilities in a fun and interactive way.",
      keyComponents: [
        "Weekly Interactive Lessons (30 minutes each)",
        "Memory Games: Simple card-matching games to enhance visual memory.",
        "Puzzle Challenges: Age-appropriate jigsaw puzzles to develop spatial reasoning.",
        "Story Time: Using picture cards to arrange stories, fostering logical thinking.",
        "Downloadable Activity Sheets for offline practice"
      ],
      skillsDeveloped: [
        "Attention span and focus",
        "Basic problem-solving strategies",
        "Visual and auditory memory"
      ]
    },
    SocialAndEmotional: {
      title: "Feelings and Friends: Building Blocks of Social Skills",
      description: "This introductory course helps young children recognize and express their emotions while learning essential social skills. Through interactive lessons and playful activities, kids will develop a foundational understanding of feelings and friendships.",
      keyComponents: [
        "Weekly Interactive Lessons (30 minutes each)",
        "Emotion Exploration: Engaging videos and stories that introduce various emotions.",
        "Friendship Activities: Simple games that promote sharing, taking turns, and cooperation.",
        "Creative Expression: Art and craft projects that allow kids to express their feelings visually.",
        "Downloadable Emotion Cards: Tools for children to identify and discuss their emotions with family and friends."
      ],
      skillsDeveloped: [
        "Basic emotional recognition and expression",
        "Understanding of friendship qualities",
        "Cooperative play and sharing"
      ]
    }
  },
  Intermediate: {
    Physical: {
      title: "Speak with Your Body: Enhancing Expressive Skills",
      description: "This course builds on the foundational skills learned in Level 1, focusing on developing expressive communication through body language, gestures, and posture. Children will engage in activities that promote self-awareness and effective non-verbal communication.",
      keyComponents: [
        "Weekly Interactive Lessons (45 minutes each)",
        "Body Language Workshops: Lessons on how posture and gestures influence communication and perception.",
        "Role-Playing Scenarios: Activities that allow students to practice expressing emotions and intentions through body language in various contexts.",
        "Confidence-Building Exercises: Activities that promote self-confidence, such as power poses and positive affirmations.",
        "Downloadable Self-Reflection Journals: Tools for students to reflect on their body language and communication experiences."
      ],
      skillsDeveloped: [
        "Enhanced awareness of non-verbal communication",
        "Improved confidence in self-expression",
        "Ability to interpret and respond to others' body language"
      ]
    },
    Linguistic: {
      title: "Mastering Language Arts: Enhancing Literacy Skills",
      description: "This course builds upon the foundational language skills acquired in Level 1, focusing on developing proficiency in reading, writing, and critical thinking. Children will engage in literature discussions, writing workshops, and research projects to enhance their linguistic abilities.",
      keyComponents: [
        "Weekly Interactive Lessons (45 minutes each)",
        "Literature Circles: Small group discussions and activities centered around age-appropriate books and stories.",
        "Writing Workshops: Lessons on various writing genres, including persuasive essays, narratives, and poetry.",
        "Research Projects: Opportunities for students to conduct research on topics of interest and present their findings.",
        "Downloadable Grammar and Style Guides: Resources for improving writing skills and understanding complex grammar concepts."
      ],
      skillsDeveloped: [
        "Proficient reading comprehension and analysis",
        "Effective writing skills across various genres",
        "Research and presentation abilities"
      ]
    },
    Cognitive: {
      title: "Mind Masters: Unlock Your Critical Thinking",
      description: "This online course builds on foundational skills and introduces more complex cognitive tasks. It encourages critical thinking, creativity, and collaboration through various projects and challenges, preparing students for academic success.",
      keyComponents: [
        "Weekly Interactive Lessons (45 minutes each)",
        "Debate Club: Simple debates on fun topics to enhance reasoning and articulation.",
        "Creative Problem Solving: Group challenges where kids must devise solutions to hypothetical scenarios.",
        "Logic Puzzles: Engaging riddles and brain teasers to sharpen logical reasoning.",
        "Group Projects: Collaborative projects that foster teamwork and presentation skills."
      ],
      skillsDeveloped: [
        "Critical thinking and analysis",
        "Creative problem-solving",
        "Communication and teamwork"
      ]
    },
    SocialAndEmotional: {
      title: "Connect and Communicate: Enhancing Social Awareness",
      description: "This course builds on foundational skills by focusing on social awareness, effective communication, and empathy. Children will engage in discussions, role-playing, and collaborative projects to enhance their interpersonal skills.",
      keyComponents: [
        "Weekly Interactive Lessons (45 minutes each)",
        "Empathy Exercises: Activities that encourage perspective-taking and understanding others' feelings.",
        "Communication Skills Workshops: Role-playing scenarios to practice active listening and assertive communication.",
        "Group Projects: Collaborative tasks that require teamwork and problem-solving.",
        "Downloadable Reflection Journals: Tools for kids to reflect on their social interactions and feelings."
      ],
      skillsDeveloped: [
        "Enhanced empathy and understanding of diverse perspectives",
        "Improved verbal and non-verbal communication skills",
        "Teamwork and collaboration abilities"
      ]
    }
  },
  Advanced: {
    Physical: {
      title: "Mastering Presence: Advanced Body Language and Confidence",
      description: "This advanced course focuses on refining non-verbal communication skills, including gestures, posture, and overall presence. Students will learn how to convey confidence and engage effectively in various social and professional situations.",
      keyComponents: [
        "Weekly Interactive Lessons (60 minutes each)",
        "Advanced Body Language Analysis: In-depth discussions on the impact of body language in different contexts, including interviews, public speaking, and social interactions.",
        "Public Speaking and Presentation Skills: Workshops that combine verbal and non-verbal communication techniques to enhance overall presentation skills.",
        "Confidence and Charisma Training: Activities designed to boost self-esteem and develop a charismatic presence.",
        "Downloadable Video Feedback Guides: Resources for students to record and analyze their body language during presentations and interactions."
      ],
      skillsDeveloped: [
        "Mastery of non-verbal communication techniques",
        "Increased confidence in public speaking and social situations",
        "Ability to project charisma and engage audiences effectively"
      ]
    },
    Linguistic: {
      title: "Elevate Your Language: Advanced Communication Skills",
      description: "This advanced course focuses on refining language skills for effective communication in various contexts, including academic settings, professional environments, and social interactions. Students will engage in debates, public speaking exercises, and cross-cultural communication workshops to enhance their linguistic abilities.",
      keyComponents: [
        "Weekly Interactive Lessons (60 minutes each)",
        "Debate and Discussion: Opportunities for students to engage in structured debates and discussions on thought-provoking topics.",
        "Public Speaking Workshops: Activities that build confidence and skills in public speaking, including presentations and speeches.",
        "Cross-Cultural Communication: Exercises and case studies that explore effective communication strategies in diverse cultural contexts.",
        "Downloadable Communication Toolkit: Resources for students to continue developing their language skills and communication strategies beyond the course."
      ],
      skillsDeveloped: [
        "Advanced language proficiency and nuance",
        "Effective communication skills in various contexts",
        "Adaptability and cultural sensitivity in communication"
      ]
    },
    Cognitive: {
      title: "Cognitive Champions: Advance Your Thinking",
      description: "This advanced online course is designed to challenge students with complex cognitive tasks. It aims to refine critical thinking, creative problem-solving, and collaborative skills through high-level projects and competitive activities.",
      keyComponents: [
        "Weekly Interactive Lessons (60 minutes each)",
        "Philosophical Debates: Encouraging students to explore ethical dilemmas and philosophical questions.",
        "Advanced Logic Puzzles: Challenging puzzles that require deep thinking and strategy.",
        "Innovation Projects: Group projects where students brainstorm and develop innovative solutions to real-world problems.",
        "Presentation Skills: Workshops on presenting ideas clearly and persuasively."
      ],
      skillsDeveloped: [
        "Advanced critical thinking and analytical skills",
        "Innovative problem-solving abilities",
        "Effective presentation and persuasion techniques"
      ]
    },
    SocialAndEmotional: {
      title: "Leadership and Empathy: Becoming a Social Leader",
      description: "This advanced course focuses on developing leadership and empathy skills. Students will engage in activities that promote self-awareness, emotional intelligence, and the ability to lead and inspire others.",
      keyComponents: [
        "Weekly Interactive Lessons (60 minutes each)",
        "Leadership Workshops: Activities that develop leadership qualities such as decision-making, responsibility, and motivation.",
        "Emotional Intelligence Training: Exercises that enhance self-awareness, empathy, and emotional regulation.",
        "Community Projects: Group projects that involve planning and executing initiatives that benefit the community.",
        "Downloadable Leadership Journals: Tools for students to reflect on their leadership journey and emotional growth."
      ],
      skillsDeveloped: [
        "Strong leadership and motivational skills",
        "High emotional intelligence",
        "Ability to inspire and positively impact others"
      ]
    }
  }
};



export const blogData = [
  {
    id: 1,
    title: '5 Ways to Boost Your Child’s Confidence in Speaking',
    description: 'Discover effective strategies to help your child become more confident in their speaking abilities...',
    imageUrl: '/2.webp',
  },
  {
    id: 2,
    title: 'The Importance of Oracy in Early Childhood Development',
    description: 'Explore why oracy is crucial for your child’s growth and how it enhances their communication skills...',
    imageUrl: '/3.webp',
  },
  {
    id: 3,
    title: 'How to Encourage Your Child to Speak Up',
    description: 'Learn techniques to motivate your child to express themselves more confidently and clearly...',
    imageUrl: '/4.webp',
  },
  {
    id: 4,
    title: 'Building Effective Communication Skills in Children',
    description: 'Tips and exercises to help your child develop strong communication skills and articulate their thoughts...',
    imageUrl: '/Carousel3.webp',
  },
  {
    id: 5,
    title: 'Overcoming Speech Anxiety in Kids',
    description: 'Understand common causes of speech anxiety in children and how to support them in overcoming their fears...',
    imageUrl: '/Carousel4.webp',
  },
];



