import React from 'react';
import { Helmet } from 'react-helmet';

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Privacy Policy | Oracy</title>
        <meta name="description" content="Privacy policy for the Oracy website." />
        <meta name="keywords" content="Oracy, privacy policy, data protection" />
      </Helmet>
      <section className="py-20 md:py-32 bg-gray-50 text-gray-800">
        <div className="max-w-5xl mx-auto px-6">
          <h2 className="text-4xl font-extrabold mb-8 text-center leading-tight text-gray-900">
            Privacy Policy
          </h2>

          <div className="space-y-12 text-left">
            <div>
              <h3 className="text-2xl font-bold mb-4 text-gray-900">1. Introduction</h3>
              <p className="text-lg text-gray-700">
                We value your privacy and are committed to protecting your personal data. This policy outlines how we collect, use, and protect your information when you visit our website or use our services.
              </p>
            </div>

            <div>
              <h3 className="text-2xl font-bold mb-4 text-gray-900">2. Information We Collect</h3>
              <ul className="list-disc list-inside text-lg text-gray-700 space-y-2">
                <li><strong>Personal Information:</strong> Name, email address, contact details, and any other information you provide during registration.</li>
                <li><strong>Usage Data:</strong> Information on how you interact with our website, including IP address, browser type, and pages visited.</li>
              </ul>
            </div>

            <div>
              <h3 className="text-2xl font-bold mb-4 text-gray-900">3. How We Use Your Information</h3>
              <ul className="list-disc list-inside text-lg text-gray-700 space-y-2">
                <li>Provide and manage our services.</li>
                <li>Improve user experience.</li>
                <li>Communicate with you regarding your account or our services.</li>
                <li>Comply with legal obligations.</li>
              </ul>
            </div>

            <div>
              <h3 className="text-2xl font-bold mb-4 text-gray-900">4. Data Sharing and Security</h3>
              <p className="text-lg text-gray-700">
                We do not sell or share your personal information with third parties except as required by law. We implement appropriate security measures to protect your data.
              </p>
            </div>

            <div>
              <h3 className="text-2xl font-bold mb-4 text-gray-900">5. Your Rights</h3>
              <p className="text-lg text-gray-700">
                You have the right to access, correct, or delete your personal information. You may also opt-out of receiving communications from us at any time.
              </p>
            </div>

            <div>
              <h3 className="text-2xl font-bold mb-4 text-gray-900">6. Changes to This Policy</h3>
              <p className="text-lg text-gray-700">
                We may update this policy from time to time. Any changes will be posted on this page.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PrivacyPolicy;
