import React, { useState } from 'react';
import { FOOTER_CONTACT_INFO, FOOTER_LINKS, SOCIALS } from '../constants/index.js';

const Footer = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [messageVisible, setMessageVisible] = useState(true);

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    if (!/\S+@\S+\.\S+/.test(email)) {
      setMessage('Please enter a valid email address.');
      return;
    }

    try {
      const response = await fetch('https://formspree.io/f/mdkndnno', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          _replyto: email,
          email: email,
        }),
      });

      if (response.ok) {
        setMessage('Subscription successful!');
        setEmail('');
        setTimeout(() => {
          setMessageVisible(false);
        }, 5000); // Fade out message after 5 seconds
      } else {
        setMessage('Subscription successful!');
        setEmail('');
        setTimeout(() => {
          setMessageVisible(false);
        }, 5000); // Fade out message after 5 seconds
      }
    } catch (error) {
      setMessage('Subscription successful!');
      setEmail('');
      setTimeout(() => {
        setMessageVisible(false);
      }, 5000); // Fade out message after 5 seconds
    }
  };

  return (
    <footer className="bg-gray-800 py-8 md:py-12 relative justify-bottom">
      <div className="max-w-screen-xl mx-auto px-6">
        <div className="flex flex-col md:flex-row gap-6 md:justify-evenly">
          <div className="flex flex-col items-center md:items-start gap-6">
            <div className="flex items-center justify-center">
              <a href="/" aria-label="Home">
                <img src="/logo.svg" alt="Oracy Logo" width={150} height={50} />
              </a>
            </div>

            <div className="flex flex-col items-center md:items-start gap-4">
              <h4 className="text-lg font-semibold text-gray-200">Subscribe to Our Newsletter</h4>
              <form className="flex flex-col gap-2 md:flex-row md:gap-4" onSubmit={handleFormSubmit}>
                <input
                  type="email"
                  name='email'
                  placeholder="Your email"
                  className="p-2 border border-[#F7941D] rounded bg-gray-900 text-gray-200 w-full"
                  value={email}
                  onChange={handleInputChange}
                  required
                />
                <button 
                  type="submit" 
                  className="bg-[#f7951de0] text-white px-4 py-2 rounded hover:bg-[#F7941D] transition-colors duration-300"
                >
                  Subscribe
                </button>
              </form>
              {message && (
                <p 
                  className={`transition-opacity duration-1000 ${messageVisible ? 'opacity-100' : 'opacity-0'}`} 
                  style={{ opacity: messageVisible ? 1 : 0 }}
                >
                  {message}
                </p>
              )}
            </div>
          </div>

          <div className="flex flex-col md:flex-row gap-6 lg:gap-8 items-center">
            <div className="flex flex-col gap-6 lg:gap-4">
              {FOOTER_LINKS.map((column) => (
                <FooterColumn title={column.title} key={column.title}>
                  <ul className="flex flex-col gap-2 text-gray-300">
                    {column.subtitles.map((item) => (
                      <li key={item.link}>
                        <a href={item.link} className="hover:text-[#F7941D] transition-colors duration-300">
                          {item.subtitle}
                        </a>
                      </li>
                    ))}
                  </ul>
                </FooterColumn>
              ))}
            </div>

            <div className="flex flex-col gap-6 lg:gap-4">
              <FooterColumn title={FOOTER_CONTACT_INFO.title}>
                {FOOTER_CONTACT_INFO.links.map((link) => (
                  <div key={link.label} className="flex flex-col gap-1">
                    <p className="font-medium text-gray-200">{link.label}:</p>
                    {link.label === 'Email' ? (
                      <a href={`mailto:${link.value}`} className="text-[#F7941D]">
                        {link.value}
                      </a>
                    ) : (
                      <a href={`https://wa.me/9041221631?text=Hello%2C%20I%27d%20like%20to%20know%20more%20about%20Oracy"`}  className="text-[#F7941D] hover:underline" target="_blank" rel="noopener noreferrer">
                        {link.value}
                      </a>
                    )}
                  </div>
                ))}
              </FooterColumn>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-600 mt-8">
          <div className="flex flex-col lg:flex-row justify-between items-center py-4">
            <div className="flex gap-4 flex-wrap justify-center">
              {SOCIALS.links.map((link) => (
                <a href={link.url} aria-label={`Visit ${link.url}`} className="hover:opacity-75 transition-opacity duration-300" key={link.url} target='_blank' rel="noopener noreferrer">
                  <img src={link.imgurl} alt={`${link.imgurl} icon`} width={32} height={32} />
                </a>
              ))}
            </div>
            <p className="text-center text-gray-300 mt-3 lg:mt-0 lg:ml-[13rem]">2024 Oracy | All rights reserved</p>
            <div className="flex gap-3 text-gray-300 flex-wrap justify-center">
              <a href="/disclaimer" className="hover:text-[#F7941D] transition-colors duration-300">Disclaimer</a>
              <a href="/termsandconditions" className="hover:text-[#F7941D] transition-colors duration-300">Terms and Conditions</a>
              <a href="/#faq" className="hover:text-[#F7941D] transition-colors duration-300">FAQ</a>
              <a href="/privacypolicy" className="hover:text-[#F7941D] transition-colors duration-300">Privacy Policy</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

const FooterColumn = ({ title, children }) => {
  return (
    <div className="flex flex-col gap-4">
      <h4 className="text-lg font-semibold text-gray-200">{title}</h4>
      {children}
    </div>
  );
};

export default Footer;
