import React from 'react';
import { Teachers } from '../constants'; // Adjust the path as needed

const TeachersPage = () => {
  return (
    <div className="bg-gray-100 min-h-screen py-10 px-4 md:px-6 lg:px-8">
      <header className="text-center mb-12">
        <h1 className="text-4xl font-bold text-gray-800 mb-4">Our Esteemed Educators</h1>
        <p className="text-lg text-gray-600">Explore the professional profiles of our exceptional educators.</p>
      </header>
      <div className="max-w-4xl mx-auto">
        {Teachers.map((teacher, index) => (
          <div
            key={index}
            className="bg-white rounded-lg shadow-lg mb-12 p-6 flex flex-col md:flex-row border border-gray-200"
          >
            <div className="flex-shrink-0 flex justify-center items-center mb-4 md:mb-0 w-full md:w-48 h-48">
              <img
                src={teacher.image}
                alt={teacher.name}
                className="w-40 h-40 object-cover rounded-full border-4 border-[#39B54A] shadow-md"
              />
            </div>
            <div className="ml-0 md:ml-6 flex-grow">
              <h2 className="text-2xl font-bold text-gray-800 mb-2">{teacher.name}</h2>
              <h3 className="text-xl font-semibold text-gray-600 mb-4">{teacher.title}</h3>
              <p className="text-base text-gray-700 mb-6">{teacher.description}</p>

              {teacher.qualifications && (
                <section className="mb-6">
                  <h4 className="text-lg font-semibold text-gray-800 mb-2">Academic Qualifications & Professional Credentials</h4>
                  <ul className="list-disc list-inside pl-5 text-base text-gray-600">
                    {teacher.qualifications.map((qual, i) => (
                      <li key={i}>{qual}</li>
                    ))}
                  </ul>
                </section>
              )}

              {teacher.experience && (
                <section className="mb-6">
                  <h4 className="text-lg font-semibold text-gray-800 mb-2">Professional Experience</h4>
                  <ul className="list-disc list-inside pl-5 text-base text-gray-600">
                    {teacher.experience.map((exp, i) => (
                      <li key={i}>{exp}</li>
                    ))}
                  </ul>
                </section>
              )}

              {teacher.skills && (
                <section className="mb-6">
                  <h4 className="text-lg font-semibold text-gray-800 mb-2">Skills & Experience</h4>
                  <ul className="list-disc list-inside pl-5 text-base text-gray-600">
                    {teacher.skills.map((skill, i) => (
                      <li key={i}>{skill}</li>
                    ))}
                  </ul>
                </section>
              )}

              {teacher.personalInterests && (
                <section className="mb-6">
                  <h4 className="text-lg font-semibold text-gray-800 mb-2">Personal Interests & Contributions</h4>
                  <ul className="list-disc list-inside pl-5 text-base text-gray-600">
                    {teacher.personalInterests.map((interest, i) => (
                      <li key={i}>{interest}</li>
                    ))}
                  </ul>
                </section>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeachersPage;
