import React from 'react';

const PromoBanner = ({ onClose }) => {
  return (
    <div
      className="fixed top-0 w-full bg-gradient-to-r from-pink-500 to-orange-500 text-white flex justify-center items-center p-2 text-center z-50"
      style={{ height: 'auto', minHeight: '60px' }} // Adjusted for mobile
    >
      <div className="flex flex-col md:flex-row items-center w-full max-w-screen-xl mx-auto px-4 md:px-6 lg:px-8">
        <p className="font-bold text-sm md:text-base lg:text-lg text-center md:text-left flex-1 md:flex-initial md:mr-3">
          Launching Soon: <span className="font-extrabold">Free Demo Classes</span>
        </p>
        <div className="flex items-center space-x-2 md:space-x-4 lg:space-x-6 mt-2 md:mt-0">
          <a
            href="/contact"
            className="bg-white text-pink-500 font-semibold py-1 px-3 rounded-lg transition duration-300 hover:bg-gray-100 text-xs md:text-sm lg:text-base"
          >
            Join Our Waitlist
          </a>
          <button
            onClick={onClose}
            className="text-white focus:outline-none text-lg md:text-xl lg:text-2xl"
            aria-label="Close banner"
          >
            ✕
          </button>
        </div>
      </div>
    </div>
  );
};

export default PromoBanner;
