import React from 'react';
import { Helmet } from 'react-helmet';

const TermsAndConditions = () => {
  return (
    <>
      <Helmet>
        <title>Terms and Conditions | Oracy</title>
        <meta name="description" content="Terms and conditions for the Oracy website." />
        <meta name="keywords" content="Oracy, terms, conditions, agreement" />
      </Helmet>
      <section className="py-20 md:py-32 bg-gray-50 text-gray-800">
        <div className="max-w-5xl mx-auto px-6">
          <h2 className="text-4xl font-extrabold mb-8 text-center leading-tight text-gray-900">
            Terms and Conditions
          </h2>

          <div className="space-y-12 text-left">
            <div>
              <h3 className="text-2xl font-bold mb-4 text-gray-900">1. Introduction</h3>
              <p className="text-lg text-gray-700">
                These terms and conditions govern your use of our website and services. By accessing our website, you agree to these terms.
              </p>
            </div>

            <div>
              <h3 className="text-2xl font-bold mb-4 text-gray-900">2. User Responsibilities</h3>
              <ul className="list-disc list-inside text-lg text-gray-700 space-y-2">
                <li>You agree to use our website and services in a lawful manner.</li>
                <li>You are responsible for maintaining the confidentiality of your account information.</li>
              </ul>
            </div>

            <div>
              <h3 className="text-2xl font-bold mb-4 text-gray-900">3. Course Enrollment and Payments</h3>
              <ul className="list-disc list-inside text-lg text-gray-700 space-y-2">
                <li>All courses are subject to availability and may be subject to change.</li>
                <li>Payments must be made in advance for the duration of the course.</li>
                <li>We reserve the right to cancel or reschedule classes as necessary.</li>
              </ul>
            </div>

            <div>
              <h3 className="text-2xl font-bold mb-4 text-gray-900">4. Intellectual Property</h3>
              <p className="text-lg text-gray-700">
                All content on this website, including text, images, and course materials, is protected by copyright and may not be used without our permission.
              </p>
            </div>

            <div>
              <h3 className="text-2xl font-bold mb-4 text-gray-900">5. Limitation of Liability</h3>
              <p className="text-lg text-gray-700">
                We are not liable for any loss or damage resulting from your use of our website or services. Our liability is limited to the amount paid for the course in which the claim arises.
              </p>
            </div>

            <div>
              <h3 className="text-2xl font-bold mb-4 text-gray-900">6. Governing Law</h3>
              <p className="text-lg text-gray-700">
                These terms are governed by the laws of [Your Jurisdiction]. Any disputes arising from these terms will be subject to the exclusive jurisdiction of the courts in [Your Jurisdiction].
              </p>
            </div>

            <div>
              <h3 className="text-2xl font-bold mb-4 text-gray-900">7. Changes to These Terms</h3>
              <p className="text-lg text-gray-700">
                We reserve the right to update these terms at any time. Changes will be posted on this page.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default TermsAndConditions;
