import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Discalimer from './components/Disclaimer';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import PressReleases from './about/PressRelease';
import TeachersPage from './components/TeachersPage';
import NotFound from './components/NotFound';
import CourseDetail from './courses/CourseDetail';

// Lazy load the components
const Home = lazy(() => import('./Home'));
const About = lazy(() => import('./about/page'));
const Contact = lazy(() => import('./contact/page'));
const Courses = lazy(() => import('./courses/page'));
const Pricing = lazy(() => import('./pricing/page'));
const Resources = lazy(() => import('./resources/page'));

function App() {
  return (
    <>
      <HelmetProvider>
        <Router>
          <Helmet>
            <meta name="author" content="Gourav" />
            <link rel="icon" href="/favicon.ico" />
            <title>Oracy</title>

            {/* Preload key CSS */}
            <link rel="preload" href="/index.css" as="style" />

            {/* Load non-critical CSS asynchronously */}
            <link rel="stylesheet" href="/index.css" media="print" onLoad="this.media='all'" />
            <noscript>{`<link rel="stylesheet" href="/index.css" />`}</noscript>

            {/* Preconnect to Google Fonts */}
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />

            {/* Load fonts with font-display swap */}
            <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800&display=swap" rel="stylesheet" />
          </Helmet>
          <Suspense fallback={<div>Loading...</div>}>
            <Navbar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/courses" element={<Courses />} />
              <Route path="/courses/:level" element={<CourseDetail />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/resources" element={<Resources />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/termsandconditions" element={<TermsAndConditions/>} />
              <Route path="/disclaimer" element={<Discalimer/>} />
              <Route path="/about/press-releases" element={<PressReleases />} />
              <Route path="/teachers" element={<TeachersPage/>} />
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
          </Suspense>
        </Router>
      </HelmetProvider>
    </>
  );
}

export default App;
