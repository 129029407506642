import React from 'react';
import { Helmet } from 'react-helmet';

const PressReleases = () => {
  const releases = [
    {
      title: "Oracy Launches New Speaking Confidence Programme for Kids",
      date: "August 10, 2024",
      summary: "Oracy announces a new programme focused on building speaking confidence in children from an early age, designed to help them become effective communicators and leaders.",
      link: "#",
    },
    {
      title: "Oracy Partners with Leading Educational Institutions",
      date: "July 20, 2024",
      summary: "In a move to expand its educational reach, Oracy partners with several renowned institutions to integrate oracy skills into their curriculums.",
      link: "#",
    },
    // Add more releases as needed
  ];

  return (
    <>
      <Helmet>
        <title>Press Releases | Oracy</title>
        <meta name="description" content="Latest press releases and news from Oracy." />
        <meta name="keywords" content="Oracy, press releases, news, media" />
      </Helmet>
      
      <section className="py-32 text-center bg-white text-gray-800">
        <div className="max-w-7xl mx-auto px-6">
          <h2 className="text-4xl font-extrabold mb-4 leading-tight">Press Releases</h2>
          <p className="text-lg md:text-lg mb-8">
            Stay updated with the latest news and announcements from Oracy.
          </p>
          
          <div className="text-left space-y-12">
            {releases.map((release, index) => (
              <div key={index} className="bg-gray-100 p-6 rounded-lg shadow-md">
                <h3 className="text-2xl font-bold mb-2">{release.title}</h3>
                <p className="text-sm text-gray-500 mb-4">{release.date}</p>
                <p className="text-gray-700 mb-4">{release.summary}</p>
                <a href={release.link} className="text-blue-600 hover:underline">Read More</a>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-16 bg-white text-gray-800">
        <div className="max-w-7xl mx-auto px-6">
          <h3 className="text-3xl font-extrabold mb-4">Subscribe for Updates</h3>
          <p className="mb-6">
            Stay informed about the latest news from Oracy by subscribing to our press release updates.
          </p>
         
        </div>
      </section>
    </>
  );
};

export default PressReleases;
