import React from 'react';
import { useParams } from 'react-router-dom';
import { coursedata } from '../constants'; 
import { FaRunning, FaBook, FaBrain, FaSmile } from 'react-icons/fa';

const icons = {
  Physical: FaRunning,
  Linguistic: FaBook,
  Cognitive: FaBrain,
  SocialAndEmotional: FaSmile,
};
const heading = {
  Physical: 'Physical',
  Linguistic: 'Linguistic',
  Cognitive: 'Cognitive',
  SocialAndEmotional: 'Social And Emotional',
}

const CourseDetail = () => {
  const { level } = useParams(); 
  const courseData = coursedata[level];

  if (!courseData) return <p>Course level not found!</p>;

  return (
    <div className="max-w-6xl mx-auto py-16 px-6 mt-[108px] space-y-12">
      <h1 className="text-4xl font-bold text-[#39B54A] mb-10 text-center">
        {level.charAt(0).toUpperCase() + level.slice(1)} Course Overview
      </h1>
      <div className="space-y-16">
        {Object.entries(courseData).map(([type, course]) => {
          const Icon = icons[type];
          const Head = heading[type];
          return (
            <div key={type} className="bg-white shadow-lg rounded-lg overflow-hidden mb-10 border-t-4 border-[#39B54A]">
              <div className="flex flex-col md:flex-row items-center p-6 bg-[#f9f9f9]">
                <div className="w-full md:w-1/2 flex items-center">
                  <Icon className="text-[#39B54A] text-5xl mr-6" />
                  <h2 className="text-3xl font-bold text-[#2a8f4e]">{Head}</h2>
                </div>
                <div className="w-full md:w-1/2">
                  <img
                    src={`/${type}.webp`}
                    alt={course.title}
                    className="w-full h-64 object-cover rounded-lg shadow-sm"
                  />
                </div>
              </div>

              <div className="p-8">
                <h3 className="text-2xl font-bold text-[#39B54A] mb-4">{course.title}</h3>
                <p className="text-lg text-gray-600 mb-8 leading-relaxed">{course.description}</p>

                <div className="mb-8">
                  <h4 className="text-xl font-semibold text-[#2a8f4e] mb-3">Key Components</h4>
                  <ul className="list-disc list-inside text-gray-600 space-y-2">
                    {course.keyComponents.map((component, index) => (
                      <li key={index} className="text-lg">{component}</li>
                    ))}
                  </ul>
                </div>

                <div>
                  <h4 className="text-xl font-semibold text-[#2a8f4e] mb-3">Skills Developed</h4>
                  <ul className="list-disc list-inside text-gray-600 space-y-2">
                    {course.skillsDeveloped.map((skill, index) => (
                      <li key={index} className="text-lg">{skill}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CourseDetail;
