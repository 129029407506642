import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { NAV_LINKS } from '../constants/index';
import PromoBanner from './PromoBanner';

const Navbar = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isScrolled, setScrolled] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  const toggleMenu = () => setMenuOpen(prev => !prev);

  // Detect scroll to add shadow or background to navbar
  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Check localStorage to see if the banner should be shown or not
  useEffect(() => {
    const bannerClosed = localStorage.getItem('bannerClosed');
    if (bannerClosed) {
      setShowBanner(false);
    }
  }, []);

  // Handle closing the banner and storing it in localStorage
  const handleCloseBanner = () => {
    setShowBanner(false);
    localStorage.setItem('bannerClosed', 'true');
  };


  return (
    <>
      {/* CTA Promotional Banner */}
      {showBanner && <PromoBanner onClose={handleCloseBanner} />}

      {/* Navbar */}
      <nav
        className={`${isScrolled ? 'fixed' : 'relative'} left-0 w-full py-4 px-4 md:px-8 lg:px-16 xl:px-[17rem] flex flex-col md:flex-row justify-between items-center z-40 transition-all duration-300 ease-in-out ${
          !isScrolled ? 'bg-transparent' : 'bg-gradient-to-r from-gray-500 to-gray-600'
        } ${isScrolled ? 'shadow-2xl' : 'shadow-md'}`}
        style={{
          minHeight: '80px',
          top: showBanner ? '60px' : '0px', // Push navbar down by the banner height
        }}
      >
        <div className="flex justify-between items-center w-full">
          <a href="/" className="flex-shrink-0">
            <img
              src={`${isScrolled ? '/logo.svg' : '/Logodark.svg'}`}
              alt="logo"
              width={160}
              height={100}
              className="transition-all duration-300"
            />
          </a>
          {/* Hamburger Icon */}
          <button
            onClick={toggleMenu}
            className={`md:hidden ${isScrolled ? 'text-white' : 'text-black'} focus:outline-none transition-transform duration-300 ${
              isMenuOpen ? 'rotate-90' : ''
            }`}
            aria-label="Toggle menu"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
        </div>

        {/* Desktop Menu Links */}
        <ul className="hidden md:flex md:items-center font-semibold md:gap-8 lg:gap-12 xl-1500:gap-16">
          {NAV_LINKS.map((link) => (
            <li key={link.key} className="flex items-center whitespace-nowrap py-2 md:py-0 text-lg">
              <a
                href={link.href}
                className={`${
                  isScrolled ? 'text-white' : 'text-black'
                } cursor-pointer pb-1.5 hover:text-[#F7941D] hover:font-semibold`}
              >
                {link.label}
              </a>
            </li>
          ))}
        </ul>

        {/* Mobile Sliding Menu */}
        <div
          className={`fixed md:hidden right-0 top-0 h-full w-full bg-gray-900 text-white z-50 transform transition-transform duration-300 ease-in-out ${
            isMenuOpen ? 'translate-x-0' : 'translate-x-full'
          }`}
        >
          <div className="flex flex-col items-center py-8">
            <button
              onClick={toggleMenu}
              className="absolute top-4 right-4 text-white focus:outline-none"
              aria-label="Close menu"
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
            <a href="/" className="mb-6">
              <img src="/logo.svg" alt="logo" width={140} height={100} />
            </a>
            <ul className="flex flex-col items-center space-y-6">
              {NAV_LINKS.map((link) => (
                <li key={link.key}>
                  <a
                    href={link.href}
                    className="text-white text-lg"
                    onClick={() => setMenuOpen(false)}
                  >
                    {link.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>

      {/* Add padding to main content */}
      <div style={{ paddingTop: showBanner ? '60px' : '0px' }}>
        {/* Main content goes here */}
      </div>
    </>
  );
};

export default Navbar;
