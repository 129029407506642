import React from 'react';
import { Helmet } from 'react-helmet';

const Disclaimer = () => {
  return (
    <>
      <Helmet>
        <title>Disclaimer | Oracy</title>
        <meta name="description" content="Disclaimer for the Oracy website." />
        <meta name="keywords" content="Oracy, disclaimer, terms" />
      </Helmet>
      <section className="py-20 md:py-32 text-center bg-white text-gray-800">
        <div className="max-w-7xl mx-auto px-6">
          <h2 className="text-4xl font-extrabold mb-4 leading-tight">Disclaimer</h2>
          <p className="text-lg md:text-lg mb-8">
            The content on this website is provided for general informational purposes only and is not intended as a substitute for professional advice. While we strive to ensure the accuracy and relevance of the information, Oracy makes no guarantees or warranties regarding the completeness or accuracy of the content presented.
          </p>
          <p className="text-lg md:text-lg mb-8">
            <strong>1. No Professional Advice:</strong> The materials and content provided on this site are for educational purposes and should not be construed as professional educational guidance. Always seek the advice of qualified professionals regarding your child's educational needs.
          </p>
          <p className="text-lg md:text-lg mb-8">
            <strong>2. Limitation of Liability:</strong> Oracy shall not be held liable for any direct, indirect, incidental, or consequential damages that may result from the use or inability to use the information provided on this site. This includes but is not limited to, any errors or omissions in the content.
          </p>
          <p className="text-lg md:text-lg mb-8">
            <strong>3. Third-Party Links:</strong> This website may contain links to third-party websites that are not controlled by Oracy. We are not responsible for the content, privacy policies, or practices of any third-party sites. The inclusion of any link does not imply endorsement by Oracy.
          </p>
          <p className="text-lg md:text-lg mb-8">
            <strong>4. Changes to Disclaimer:</strong> Oracy reserves the right to modify this disclaimer at any time without prior notice. Your continued use of the site signifies your acceptance of any such changes.
          </p>
          <p className="text-lg md:text-lg mb-8">
            By using our website, you agree to this disclaimer. If you do not agree with any part of this disclaimer, please do not use our website or services.
          </p>
        </div>
      </section>
    </>
  );
};

export default Disclaimer;
